<template>
  <div class="kpi">
    <v-skeleton-loader
      v-if="cargandoDatos"
      class="cargando"
      type="image"
      height="500px"
      max-height="500px"
    ></v-skeleton-loader>
    <div v-else>
      <div class="graficaVacia" v-if="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-if="!graficaVacia" class="canvas">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";
export default {
  name: "MutacionesBiomarcadores",
  props: {
  selectedCountry: {
    type: String,
    default: 'argentina',
  },
},
  components: { highcharts: Chart },
  data() {
    return {
      cargandoDatos: true,
      graficaVacia: false,
      colors: ["#FDCA40", "#356CFF", "#3ECF8E"],
      colorsKPIDona: ["#5AC5F3", "#EC4860", "#4C2783", "#ED9E30"],
      filtros: this.$store.state.filtros,
      mutaciones: [],
      categorias: [],
    };
  },
watch: {
  filtros: {
    handler() {
      this.actualizarGrafica();
    },
    deep: true,
  },
  selectedCountry() {
    this.actualizarGrafica();
  },
},
  computed: {
    chartOptions() {
      const ctx = this;
      return {
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
          events: {
            drilldown: function (e) {
              if (!e.points || e.points[0] == e.point) {
                ctx.cargandoDatosVariante(true);
                obtenerDatosKPI(
                  ctx,
                  "/mutaciones-biomarcadores/variantes",
                  ctx.$store.getters.objetoPeticion(e.point.idBiomarcador)
                ).then((resp) => {
                  ctx.cargandoDatosVariante(false);
                  const numeroColumnas = 4;
                  const valorInicialEjeX = 70;
                  const valorInicialEjeY = 50;
                  let contador = 1;
                  let valorEjeX = valorInicialEjeX;
                  let valorEjeY = valorInicialEjeY;
                  const tipoTumoralAgrupado = ctx.agruparObjeto(
                    resp,
                    "tipo_tumoral"
                  );
                  const keys = Object.keys(tipoTumoralAgrupado);
                  const espacio = parseInt(keys.length / numeroColumnas);
                  this.update({
                    chart: {
                      height:
                        espacio === 1 || espacio === 0
                          ? 400
                          : (keys.length / numeroColumnas) * 205,
                    },
                  });
                  keys.forEach((r) => {
                    const max = tipoTumoralAgrupado[r].reduce(
                      (total, currentValue) => {
                        return total + currentValue.total;
                      },
                      0
                    );
                    this.addSingleSeriesAsDrilldown(e.point, {
                      title: {
                        format: "<b>{name}</b>",
                        verticalAlign: "top",
                        align: "center",
                        y: -23,
                      },
                      subtitle: {
                        format: `${max}`,
                        verticalAlign: "middle",
                        align: "center",
                        y: 1,
                      },
                      name: `${e.point.name} ${r}`,
                      type: "pie",
                      size: 125,
                      dataLabels: {
                        color: "white",
                        style: {
                          textOutline: false,
                        },
                        distance: "-24%",
                        formatter: function () {
                          return `${this.point.cantidad}`;
                        },
                      },
                      innerSize: "50%",
                      data: tipoTumoralAgrupado[r].map((e, index) => {
                        return {
                          name: e.variante,
                          y: parseInt(((e.total / max) * 100).toFixed(0)),
                          cantidad: e.total,
                          color: ctx.colorsKPIDona[index],
                        };
                      }),
                      center: [valorEjeX, valorEjeY],
                    });
                    if (contador === numeroColumnas) {
                      valorEjeY += 180;
                      valorEjeX = valorInicialEjeX;
                      contador = 1;
                    } else {
                      valorEjeX += 200;
                      contador++;
                    }
                  });
                  this.applyDrilldown();
                });
              }
            },
            drillup: function () {
              this.update({
                chart: {
                  height: 400,
                },
              });
            },
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: this.categorias,
        },
        yAxis: {
          title: {
            text: "",
          },
          showEmpty: false,
        },
        tooltip: {
          formatter: function () {
            if (this.x) {
              return `<b>${this.x}</b> <br/> 
              Total: ${this.point.total}`;
            } else {
              return `<b>${this.key}</b> <br/> 
              Porcentaje: ${this.y}%`;
            }
          },
        },
        series: this.mutaciones,
        drilldown: {
          series: [],
        },
      };
    },
  },
  methods: {
    actualizarGrafica() {
      if (this.filtros.fecha.desde) {
        this.cargandoDatos = true;
    obtenerDatosKPI(
      this,
      '/mutaciones-biomarcadores', 
      this.$store.getters.objetoPeticion(), 
      this.selectedCountry, // Pasamos selectedCountry como cuarto parámetro
      process.env.VUE_APP_URL_MS 
    ).then((resp) => {
          if (resp.length > 0) {
            this.graficaVacia = false;
            this.categorias = resp.map((r) => r.biomarcador);
            this.mutaciones = [
              {
                showInLegend: false,
                colorByPoint: true,
                data: resp.map((r) => {
                  return {
                    name: r.biomarcador,
                    y: r.total,
                    total: r.total,
                    idBiomarcador: r.id,
                    drilldown: true,
                  };
                }),
              },
            ];
          } else {
            this.graficaVacia = true;
          }
          this.cargandoDatos = false;
        });
      }
    },
    agruparObjeto(arreglo = [], agrupador) {
      return arreglo.reduce((r, a) => {
        r[a[agrupador]] = [...(r[a[agrupador]] || []), a];
        return r;
      }, {});
    },
    cargandoDatosVariante(valor) {
      this.$emit('cargandoDatos', valor);
    },
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  min-height: 400px;
  height: 100%;
}
.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-weight: 500;
}
</style>