import request from "../../utils/request";
import getHeaders from "../../utils/headers";

// export default function obtenerDatosKPI(contexto, url, filtros) {
//     return request(`/graficas${url}`, {
//         method: "POST",
//         headers: getHeaders(contexto),
//         body: JSON.stringify({ filtros }),
//     }, contexto)
// }

// Modificación para aceptar una URL base alternativa
export default function obtenerDatosKPI(
    contexto,
    url,
    filtros,
    selectedCountry = 'argentina',
    baseURL = process.env.VUE_APP_SERVER_URL
  ) {
    const prefix = selectedCountry === 'argentina' ? '/graficas' : '/graficas-mex';
  
    return request(
      `${baseURL}${prefix}${url}`,
      {
        method: 'POST',
        headers: getHeaders(contexto),
        body: JSON.stringify({ filtros }),
      },
      contexto
    );
  }
  