function parseJSON(response) {
    return new Promise((resolve) => response.json()
        .then((json) => resolve({
            status: response.status,
            ok: response.ok,
            json,
        })));
}

export default function request(url, options, context) {
    // Detectar si la URL ya incluye un protocolo y hostname.
    const fullUrl = url.startsWith('http') ? url : process.env.VUE_APP_SERVER_URL + url;
    return new Promise((resolve, reject) => {
        fetch(fullUrl, options)
            .then(parseJSON)
            .then((response) => {
                if (response.ok) {
                    return resolve(response.json);
                }
                if (response.status === 401) {
                    if (context.$route.name !== 'Login') {
                        context.$store.commit('setUsuario', { rol: null, nombres: null, token: null })
                    }
                } else
                    return reject(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
