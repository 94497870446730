<template>
  <div class="autocomplete">
    <v-autocomplete
      v-model="autocomplete"
      :loading="estaCargando"
      :items="items"
      :search-input.sync="descripcion"
      item-text="descripcion"
      item-value="id"
      return-object
      @change="onChange"
      outlined
      full-width
      hide-details
      no-data-text="Sin datos disponibles"
      :label="etiqueta"
    ></v-autocomplete>
    <div class="chips">
      <v-chip
        class="short"
        color="purple darken-2"
        close
        label
        text-color="white"
        v-for="item in seleccionados"
        :key="item.id"
        @click:close="remover(item)"
      >
        <span>{{ item.descripcion }}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import { obtenerDatosFiltroSelect } from "../../services/filtros/filtros.service";
export default {
  name: "Autocomplete",
  props: {
  filtro: String,
  etiqueta: String,
  selectedCountry: {
    type: String,
    default: 'argentina',
  },
},
  data() {
    return {
      estaCargando: false,
      items: [],
      descripcion: null,
      autocomplete: null,
      seleccionados: this.$store.state.filtros[this.filtro],
    };
  },
  watch: {
  selectedCountry() {
    this.reset();
    obtenerDatosFiltroSelect(this, this.filtro, this.selectedCountry).then(
      (resp) => (this.items = resp)
    );
  },
},
  created() {
    obtenerDatosFiltroSelect(this, this.filtro).then(
      (resp) => (this.items = resp)
    );
  },

  methods: {
    reset() {
      this.seleccionados = [];
      this.$nextTick(() => {
        this.descripcion = "";
        this.autocomplete = null;
      });
      this.cambiarEstadoFiltro();
    },
    remover(item) {
      this.seleccionados.splice(this.seleccionados.indexOf(item), 1);
      this.seleccionados = [...this.seleccionados];
      this.cambiarEstadoFiltro();
    },
    onChange(value) {
      if (!this.seleccionados.find((r) => r.id == value.id))
        this.seleccionados.push(value);
      this.cambiarEstadoFiltro();
      this.$nextTick(() => {
        this.descripcion = "";
        this.autocomplete = null;
      });
    },
    cambiarEstadoFiltro() {
      this.$store.commit({
        type: "agregarFiltro",
        filtro: this.filtro,
        valor: this.seleccionados,
      });
    },
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
  top: 0.5em;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin: 0.5rem 0;
}

.short {
  max-width: 250px;
}
.short span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>