import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard'
import jwt_decode from "jwt-decode";
import ForgotPassword from '../views/Usuarios/ForgotPassword';
import ResetPassword from '../views/Usuarios/ResetPassword';

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { authorize: [] }
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        meta: { authorize: ['Admin'] },
        component: () =>
            import ( /* webpackChunkName: "usuarios" */ '../views/Usuarios/Usuarios.vue')
    },
    {
        path: '/login',
        name: 'Login',
        props: true,
        component: () =>
            import ( /* webpackChunkName: "Login" */ '../views/Usuarios/Login.vue')
    },
    {
        path: '/validar/:uuid',
        name: 'CrearContrasena',
        component: () =>
            import ( /* webpackChunkName: "CrearContrasena" */ '../views/Usuarios/CrearContrasena.vue')
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const { authorize } = to.meta;
    const token = localStorage.getItem('token');
    const currentUser = token !== 'null' && token ? jwt_decode(token) : null
    if (authorize) {
        if (!currentUser) {
            return next({ path: '/login' });
        }

        if (authorize.length && !authorize.includes(currentUser.rol)) {
            return next({ path: '/' });
        }
    } else if (currentUser && to.name === 'Login') {
        return next({ path: '/' });
    }

    next();
})

export default router