<template>
  <v-app >
    <div class="container-app" v-if="token">
      <Navbar />
      <v-main>
        <router-view />
      </v-main>
    </div>
    <div v-else>
        <router-view />
    </div>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'
import jwt_decode  from "jwt-decode";
export default {
  name: "App",
  components: {
    Navbar
  },
  watch: {
    token(value){
      if(!value && !this.$store.state.usuario.validar)
        this.$router.push("login")
    }
  },
  computed: {
    token: {
      get () {
        return this.$store.state.usuario.token;
      }
    }
  },
  created(){
    const token = localStorage.getItem('token');
    if (token !== 'null' && token) {
      const { nombres, rol } = jwt_decode(token)
      this.$store.commit('setUsuario', {rol, nombres, token})
    } else {
      this.$store.commit('setUsuario', {rol: null, nombres: null, token: null})
    }
  },
};
</script>

<style>
@font-face {
  font-family: "Barlow";
  src: local("Barlow"),
   url(./assets/fonts/Barlow-Regular.ttf) format("truetype");
}

#app {
  background: transparent;
  font-family: "Barlow", Helvetica, Arial;
}

.container-app{
  margin: 3rem 18rem 0;
}

@media (max-width: 1780px) {
  .container-app{
    margin: 3rem 5rem 0;
  }
}
</style>
