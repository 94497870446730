<template>
  <nav class="navbar">
    <img src="../assets/biomakers_logo.png" alt="" />
    <div class="botones">
      <v-btn-toggle v-model="option" tile color="white" group light mandatory>
        <v-btn
          value="Dashboard"
          text
          color="white"
          @click="cambiarADashboard()"
          class="btn-navbar"
        >
          Dashboard
        </v-btn>
        <v-btn
          value="Usuarios"
          text
          color="white"
          @click="cambiarAUsuarios()"
          class="btn-navbar"
          v-if="rol === 'Admin'"
        >
          Usuarios
        </v-btn>
      </v-btn-toggle>
      <hr />
      <v-btn text large color="white" class="btn-navbar" @click="cerrarSesion">
        <v-icon left> mdi-logout </v-icon> Cerrar sesión
      </v-btn>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    rol: {
      get() {
        return this.$store.state.usuario.rol;
      },
    },
    option: {
      get() {
        return this.$route.name;
      },
      set(value) {
        return value;
      }
    },
  },
  methods: {
    cambiarADashboard() {
      if (this.option !== "Dashboard") this.$router.push("dashboard");
    },
    cambiarAUsuarios() {
      if (this.option !== "Usuarios") this.$router.push("usuarios");
    },
    cerrarSesion() {
      localStorage.removeItem("token");
      this.$store.commit("setUsuario", {
        rol: null,
        nombres: null,
        token: null,
      });
    },
  },
};
</script>

<style scoped>
.btn-navbar {
  text-transform: capitalize;
  font-size: 16px;
}

.navbar {
  display: grid;
  grid-template-columns: 60% 40%;
}

.botones {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-end;
}
.v-btn-toggle > .v-btn.v-btn--active {
  background-color: transparent !important;
  font-weight: 600;
  border-bottom: white 1px solid;
}

.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  display: none;
}

hr {
  border: none;
  border-left: 1px solid white;
  height: 100%;
  width: 1px;
}

@media (max-width: 840px) {
  .navbar {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>