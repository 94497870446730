<template>
  <v-card elevation="1">
    <div class="content">
      <div class="resultado">
        <span class="titulo">{{ titulo }}</span>
        <div v-if="cargando">
          <v-skeleton-loader
            class="loader"
            type="text"
            max-height="100px"
            height="30px"
          ></v-skeleton-loader>
        </div>
        <div v-else>
          <div class="total">
            <span class="texto-total">{{ total }}</span>
            <span class="texto-opcional" v-if="textoOpcional">
              {{ textoOpcional }}</span
            >
          </div>
        </div>
      </div>

      <div class="icon">
        <svg
          v-if="icono === 'tiempo'"
          width="49"
          height="49"
          viewBox="0 0 49 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.4428 0.03125C11.0639 0.03125 0.224022 10.8711 0.224022 24.25C0.224022 37.6289 11.0639 48.4688 24.4428 48.4688C37.8217 48.4688 48.6615 37.6289 48.6615 24.25C48.6615 10.8711 37.8217 0.03125 24.4428 0.03125ZM33.4271 30.5977L31.474 33.0391C31.1811 33.4297 30.7904 33.7227 30.2045 33.7227C29.9115 33.7227 29.5209 33.5273 29.3256 33.332L22.7826 28.4492C21.8061 27.7656 21.3178 26.6914 21.3178 25.4219V10.1875C21.3178 9.40625 22.0014 8.625 22.8803 8.625H26.0053C26.7865 8.625 27.5678 9.40625 27.5678 10.1875V24.25L33.2318 28.4492C33.5248 28.7422 33.8178 29.1328 33.8178 29.6211C33.8178 30.0117 33.6225 30.4023 33.4271 30.5977Z"
            fill="white"
          />
        </svg>

        <svg
          v-if="icono === 'cliente'"
          width="63"
          height="45"
          viewBox="0 0 63 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.56777 19.125C12.9857 19.125 15.8178 16.3906 15.8178 12.875C15.8178 9.45703 12.9857 6.625 9.56777 6.625C6.05215 6.625 3.31777 9.45703 3.31777 12.875C3.31777 16.3906 6.05215 19.125 9.56777 19.125ZM53.3178 19.125C56.7357 19.125 59.5678 16.3906 59.5678 12.875C59.5678 9.45703 56.7357 6.625 53.3178 6.625C49.8021 6.625 47.0678 9.45703 47.0678 12.875C47.0678 16.3906 49.8021 19.125 53.3178 19.125ZM56.4428 22.25H50.1928C48.435 22.25 46.8725 23.0312 45.7006 24.1055C49.7045 26.2539 52.4389 30.1602 53.1225 34.75H59.5678C61.2279 34.75 62.6928 33.3828 62.6928 31.625V28.5C62.6928 25.082 59.8607 22.25 56.4428 22.25ZM31.4428 22.25C37.3998 22.25 42.3803 17.3672 42.3803 11.3125C42.3803 5.35547 37.3998 0.375 31.4428 0.375C25.3881 0.375 20.5053 5.35547 20.5053 11.3125C20.5053 17.3672 25.3881 22.25 31.4428 22.25ZM38.8646 25.375H38.0834C36.0326 26.3516 33.7865 26.9375 31.4428 26.9375C29.0014 26.9375 26.7553 26.3516 24.7045 25.375H23.9232C17.6732 25.375 12.6928 30.4531 12.6928 36.7031V39.4375C12.6928 42.0742 14.7436 44.125 17.3803 44.125H45.5053C48.0443 44.125 50.1928 42.0742 50.1928 39.4375V36.7031C50.1928 30.4531 45.1146 25.375 38.8646 25.375ZM17.0873 24.1055C15.9154 23.0312 14.3529 22.25 12.6928 22.25H6.44277C2.92715 22.25 0.192772 25.082 0.192772 28.5V31.625C0.192772 33.3828 1.55996 34.75 3.31777 34.75H9.66543C10.349 30.1602 13.0834 26.2539 17.0873 24.1055Z"
            fill="white"
          />
        </svg>
        <svg
          v-if="icono === 'muestra'"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="prescription-bottle"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          class="svg-inline--fa fa-w-12"
        >
          <path
            fill="currentColor"
            d="M32 192h120c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H32v64h120c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H32v64h120c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H32v64c0 17.6 14.4 32 32 32h256c17.6 0 32-14.4 32-32V128H32v64zM360 0H24C10.8 0 0 10.8 0 24v48c0 13.2 10.8 24 24 24h336c13.2 0 24-10.8 24-24V24c0-13.2-10.8-24-24-24z"
            class=""
          ></path>
        </svg>
      </div>
    </div>
  </v-card>
</template>

<script>
import obtenerDatosKPI from "../services/graficas/obtenerDatos";
import { formatoMiles } from "../utils/number-format";
export default {
  name: "CardTotal",
  props: {
    url: null,
    titulo: null,
    textoOpcional: null,
    icono: null,
    selectedCountry: {
      type: String,
      default: 'argentina',
    },
  },
  watch: {
    filtros: {
      handler() {
        this.actualizarCard();
      },
      deep: true,
    },
    selectedCountry() {
      this.actualizarCard();
    },
  },
  data() {
    return {
      cargando: true,
      filtros: this.$store.state.filtros,
      total: null,
    };
  },
  methods: {
actualizarCard() {
  if (this.filtros.fecha.desde) {
    this.cargando = true;

    obtenerDatosKPI(
      this,
      this.url, // Pasamos la URL sin prefijo
      this.$store.getters.objetoPeticion(),
      this.selectedCountry, // Pasamos selectedCountry como cuarto parámetro
      process.env.VUE_APP_URL_MS // Pasamos baseURL como quinto parámetro (si es necesario)
    ).then((resp) => {
      this.total =
        typeof resp.total === "number"
          ? formatoMiles(resp.total)
          : resp.total;
      this.cargando = false;
    });
  }
},

  },
  created() {
    this.actualizarCard();
  },
};
</script>


<style scoped>
.svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}
.content {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 30%;
  height: 80px;
  border-radius: 6px;
  background: linear-gradient(135.78deg, #0044ac, #356cff 84.38%);
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 50px;
}

.loader {
  margin-top: 1em;
}

.resultado {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.titulo {
  font-weight: 600;
  font-size: 18px;
}

.total {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.texto-total {
  font-weight: 600;
  font-size: 30px;
  display: inline;
}

.texto-opcional {
  font-size: 14px;
  position: relative;
  top: 18px;
}
</style>