const eliminarRepetidos = (value, index, self) => {
    return self.indexOf(value) === index
}

const crearEtiquetaMes = ({ mes, anio }) => { return `${mes.substring(0, 3).toUpperCase()}-${anio.toString().substring(2, 4)}` }

const agruparPaises = (arreglo = []) => {
    return arreglo.reduce((r, a) => {
        r[a.pais] = [...(r[a.pais] || []), a]
        return r
    }, {})
}

const crearOpcionesErrorEstandar = (paisesAgrupados = [], etiquetas = []) => {
    const data = Array(etiquetas.length).fill([0, 0]);
    paisesAgrupados.forEach(r => {
        const index = etiquetas.indexOf(crearEtiquetaMes(r))
        data[index] = [r.std[0] < 1 ? 1 : Math.round(r.std[0]), Math.round(r.std[1])];
    });
    return {
        type: 'errorbar',
        data,
        tooltip: {
            pointFormat: '(margen de error: {point.low}-{point.high})<br/>'
        }
    }
}

export const obtenerEtiquetasMeses = (arreglo = []) => {
    const etiquetas = arreglo.map(
        (el) => crearEtiquetaMes(el)
    )
    return etiquetas.filter(eliminarRepetidos)
}

export const obtenerDatasetsMeses = (arreglo = [], etiquetas = [], mostrarErrorEstandar = false) => {
    const paisesAgrupados = agruparPaises(arreglo)
    const keys = Object.keys(paisesAgrupados)
    const dataset = []
    let data;
    keys.forEach((key) => {
        data = Array(etiquetas.length).fill(0);
        paisesAgrupados[key].forEach(r => {
            const index = etiquetas.indexOf(crearEtiquetaMes(r))
            data[index] = Math.round(r.total)
        });
        if (mostrarErrorEstandar) {
            dataset.push({
                name: key,
                data,
            }, crearOpcionesErrorEstandar(paisesAgrupados[key], etiquetas));
        } else {
            dataset.push({
                name: key,
                data,
            });
        }
    });
    return dataset
}