<template>
  <div class="kpi">
    <v-skeleton-loader
      v-if="cargandoDatos"
      class="cargando"
      type="image"
      height="400px"
      max-height="1500px"
    ></v-skeleton-loader>
    <div v-else>
      <div class="graficaVacia" v-if="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-else class="canvas">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";
import { formatoMiles } from '../../utils/number-format';
export default {
  name: "MutacionesTipoTumoral",
  props: {
  selectedCountry: {
    type: String,
    default: 'argentina',
  },
},
  components: { highcharts: Chart },
  data() {
    return {
      graficaVacia: false,
      cargandoDatos: true,
      filtros: this.$store.state.filtros,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "bar",
          zoomType: "xy",
          height: 1200,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
          title: {
            text: "TIPO TUMORAL",
          },
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          max: 100,
          title: {
            text: "NÚMERO DE PRUEBAS",
          },
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
              color: "black",
              style: {
                textOutline: false,
                fontWeight: 400,
                fontSize: "12px",
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.x} - ${this.point.name}</b> <br/> 
              Porcentaje: ${this.y}%<br/>
              Registros: ${this.point.cantidad}`;
          },
        },
        series: [
          {
            showInLegend: false,
            colorByPoint: true,
            data: [],
          },
        ],
      },
    };
  },
watch: {
  filtros: {
    handler() {
      this.actualizarGrafica();
    },
    deep: true,
  },
  selectedCountry() {
    this.actualizarGrafica();
  },
},
  methods: {
    actualizarGrafica() {
      if (this.filtros.fecha.desde) {
        this.cargandoDatos = true;
        obtenerDatosKPI(
      this,
      '/mutaciones-tipo-tumoral', 
      this.$store.getters.objetoPeticion(), 
      this.selectedCountry, // Pasamos selectedCountry como cuarto parámetro
      process.env.VUE_APP_URL_MS 
    ).then((resp) => {
          if (resp.length > 0) {
            this.graficaVacia = false;
            this.chartOptions.series.data = [];
            const tipoAgrupados = this.agruparTiposTumoral(resp);
            const tiposTumoral = this.ordenarObjetoTiposTumoral(tipoAgrupados);
            this.chartOptions.chart.height = tiposTumoral.length > 10 ? 45 * tiposTumoral.length : 400;
            this.chartOptions.xAxis.categories = tiposTumoral.map(r => `${r.tipoTumoral} ${formatoMiles(r.max)}/${formatoMiles(r.totalRegistros)}`);
            const objetoGrafica = [];
            tiposTumoral.forEach((r, index) => {
              objetoGrafica.push(
                ...r.data.map((e) => {
                  return {
                    name: e.descripcion,
                    y: parseFloat(((e.total / r.max) * 100).toFixed(3)),
                    x: index,
                    cantidad: e.total
                  };
                })
              );
            });
            this.chartOptions.series[0].data = objetoGrafica;
          } else {
            this.graficaVacia = true;
          }
          this.cargandoDatos = false;
        });
      }
    },
    ordenarObjetoTiposTumoral(tiposTumoral) {
      const nuevoObjeto = [];
      Object.keys(tiposTumoral).forEach((r) => {
        nuevoObjeto.push({
          tipoTumoral: r,
          totalRegistros: tiposTumoral[r][0].totalRegistros,
          max: tiposTumoral[r].reduce((prev, curr) => prev + curr.total, 0),
          data: tiposTumoral[r]
            .map((e) => {
              return { total: e.total, descripcion: e.descripcion};
            })
            .sort((a, b) => b.total - a.total),
        });
      });
      nuevoObjeto.sort((a, b) => b.max - a.max);
      return nuevoObjeto;
    },
    agruparTiposTumoral(arreglo) {
      return arreglo.reduce((r, a) => {
        r[a.tipo_tumoral] = [...(r[a.tipo_tumoral] || []), a];
        return r;
      }, {});
    },
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  min-height: 400px;
  max-height: 2000px;
}
.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-weight: 500;
}
</style>