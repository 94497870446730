<template>
  <div class="kpi">
    <v-skeleton-loader
      v-if="cargandoDatos"
      type="image"
      height="600px"
      max-height="600px"
    ></v-skeleton-loader>
    <div v-else>
      <div class="graficaVacia" v-if="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-if="!graficaVacia" class="canvas">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";
export default {
  name: "TipoResultado",
  props: {
  selectedCountry: {
    type: String,
    default: 'argentina',
  },
},
  components: {
    highcharts: Chart,
  },
  watch: {
    filtros: {
      handler() {
        this.actualizarGrafica();
      },
      deep: true,
    },
  },
  data() {
    return {
      cargandoDatos: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          height: 600,
          type: "bar",
          events: {
            load: function () {
              const chart = this,
                points = chart.series[0].data,
                options = {
                  dataLabels: {
                    inside: false,
                  },
                };

              points.forEach(function (point) {
                if (point.shapeArgs.height < 50) {
                  point.update(options, false);
                }
              });

              chart.redraw();
            },
          },
        },
        plotOptions: {
          series: {
            borderRadius: 4,
            dataLabels: {
              color: "black",
              style: {
                textOutline: false,
                fontWeight: 400,
                fontSize: "14px",
              },
              inside: true,
              enabled: true,
              align: "right",
              formatter: function () {
                const num = this.point.total;
                return `${
                  Math.abs(num) > 999
                    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                    : Math.sign(num) * Math.abs(num)
                }`;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.x}</b> <br/> 
              Porcentaje: ${this.y}% <br/>
              Total: ${this.point.total}`;
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
          min: 0,
          max: 100,
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        series: [],
      },
      graficaVacia: false,
      filtros: this.$store.state.filtros,
    };
  },
  methods: {
    actualizarGrafica() {
      if (this.filtros.fecha.desde) {
        this.cargandoDatos = true;
        obtenerDatosKPI(
          this,
          "/tipo-resultado",
          this.$store.getters.objetoPeticion(), 
          this.selectedCountry, // Pasamos selectedCountry como cuarto parámetro
      process.env.VUE_APP_URL_MS 
        ).then((resp) => {
          if (resp.length > 0) {
            const max = resp.reduce((total, currentValue) => {
              return total + currentValue.total;
            }, 0);
            this.chartOptions.series = [];
            this.graficaVacia = false;
            this.chartOptions.xAxis.categories = resp.map((r) => r.descripcion);
            this.chartOptions.series.push({
              showInLegend: false,
              data: resp.map((r) => {
                return {
                  y: parseInt(((r.total / max) * 100).toFixed(0)),
                  total: r.total,
                  color: r.color,
                };
              }),
            });
          } else {
            this.graficaVacia = true;
          }
          this.cargandoDatos = false;
        });
      }
    },
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  height: 600px;
}

.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  font-weight: 500;
}
</style>