<template>
 <div class="kpi">
    <v-skeleton-loader
      v-if="cargandoDatos"
      class="cargando"
      type="image"
      height="400px"
      max-height="400px"
    ></v-skeleton-loader>
    <div v-else>
      <div class="graficaVacia" v-if="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-if="!graficaVacia" class="canvas">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import {
  obtenerEtiquetasMeses,
  obtenerDatasetsMeses,
} from "../../utils/datasets.js";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";
export default {
  name: "EvolucionIngresoMuestras",
  props: {
  selectedCountry: {
    type: String,
    default: 'argentina',
  },
},
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      cargandoDatos: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          zoomType: "x",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: "Número de muestras",
          },
        },
        tooltip: {
          shared: true,
        },
        series: [],
      },
      graficaVacia: false,
      filtros: this.$store.state.filtros,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.actualizarGrafica();
      },
      deep: true,
    },
    selectedCountry() {
      this.actualizarGrafica();
    },
  },

  methods: {
    actualizarGrafica() {
      if (this.filtros.fecha.desde) {
        this.cargandoDatos = true;
        this.chartOptions.xAxis.categories = [];
        this.chartOptions.series = [];
        obtenerDatosKPI(
      this,
      '/evolucion-muestras', 
      this.$store.getters.objetoPeticion(), 
      this.selectedCountry, // Pasamos selectedCountry como cuarto parámetro
      process.env.VUE_APP_URL_MS 
    )
          .then((resp) => {
            if (resp.length > 0) {
              const etiquetas = obtenerEtiquetasMeses(resp);
              this.chartOptions.xAxis.categories = etiquetas;
              this.chartOptions.series = obtenerDatasetsMeses(resp, etiquetas);
              this.graficaVacia = false;
            } else {
              this.graficaVacia = true;
            }
            this.cargandoDatos = false;
          });
      }
    },
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  min-height: 400px;
}
.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-weight: 500;
}
</style>