<template>
  <div class="kpi">
    <v-skeleton-loader
      v-if="cargandoDatos"
      class="cargando"
      type="image"
      height="500px"
      max-height="500px"
    ></v-skeleton-loader>
    <div v-else>
      <div class="graficaVacia" v-if="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-if="!graficaVacia" class="canvas">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";
export default {
  name: "DeterminacionesPais",
  components: { highcharts: Chart },
  props: {
    selectedCountry: {
      type: String,
      default: 'argentina',
    },
  },
  data() {
    return {
      cargandoDatos: true,
      colors: [
        "#FF685F",
        "#356CFF",
        "#EC4860",
        "#FEAC00",
        "#F8E85F",
        "#34BC62",
      ],
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
          height: 500,
        },
        plotOptions: {
          series: {
            borderRadius: 4,
            dataLabels: {
              color: "black",
              style: {
                textOutline: false,
                fontWeight: 400,
                fontSize: "14px",
              },
              enabled: true,
              formatter: function () {
                const num = this.point.total;
                return `${
                  Math.abs(num) > 999
                    ? (Math.sign(num) * Math.abs(num) / 1000).toFixed(1) + "k"
                    : Math.sign(num) * Math.abs(num)
                }`;
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.x}</b> <br/> 
              Porcentaje: ${this.y}% <br/>
              Total: ${this.point.total}`;
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "",
          },
          min: 0,
          max: 100,
          labels: {
            formatter: function () {
              return this.value + "%";
            },
          },
        },
        series: [],
      },
      graficaVacia: false,
      filtros: this.$store.state.filtros,
    };
  },
  watch: {
    filtros: {
      handler() {
        this.actualizarGrafica();
      },
      deep: true,
    },
    selectedCountry() {
      this.actualizarGrafica();
    },
  },
  methods: {
    actualizarGrafica() {
      if (this.filtros.fecha.desde) {
        this.cargandoDatos = true;

        // Llamamos a obtenerDatosKPI con los parámetros correctos
        obtenerDatosKPI(
          this,
          '/determinaciones-resuletas-pais', // URL sin prefijo
          this.$store.getters.objetoPeticion(),
          this.selectedCountry, // Pasamos selectedCountry como cuarto parámetro
          process.env.VUE_APP_URL_MS // Base URL si es necesario
        )
          .then((resp) => {
            if (resp.some((r) => r.total > 0)) {
              this.chartOptions.series = [];
              this.graficaVacia = false;
              this.chartOptions.xAxis.categories = resp.map((r) => r.pais);
              const max = resp.reduce((total, currentValue) => {
                return total + currentValue.total;
              }, 0);
              this.chartOptions.series.push({
                showInLegend: false,
                data: resp.map((r, index) => {
                  return {
                    y: parseFloat(((r.total / max) * 100).toFixed(3)),
                    total: r.total,
                    color: this.colors[index],
                  };
                }),
              });
            } else {
              this.graficaVacia = true;
            }
            this.cargandoDatos = false;
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
            this.cargandoDatos = false;
            this.graficaVacia = true;
          });
      }
    },
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  min-height: 500px;
}
.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  font-weight: 500;
}
</style>
