<template>
  <div class="kpi">
    <v-skeleton-loader
      v-if="cargandoDatos"
      class="cargando"
      type="image"
      height="500px"
      max-height="500px"
    ></v-skeleton-loader>
    <div v-else>
      <div v-if="graficaVacia" class="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-else class="canvas">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";

export default {
  name: "MutacionesBiomarcadores",
  props: {
  selectedCountry: {
    type: String,
    default: 'argentina',
  },
},
  components: { highcharts: Chart },
  data() {
    return {
      cargandoDatos: true,
      graficaVacia: false,
      colors: ["#FDCA40", "#356CFF", "#3ECF8E"],
      colorsKPIDona: ["#5AC5F3", "#EC4860", "#4C2783", "#ED9E30"],
      filtros: this.$store.state.filtros,
      mutaciones: [],
      categorias: [],
    };
  },
  watch: {
    'filtros.fecha': {
      handler() {
        this.actualizarGrafica();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    chartOptions() {
      const procesarDatos = (data, tipo) => {
        const filteredData = data
          .filter(d => parseFloat(d[tipo]) > 0)
          .map(d => parseFloat(d[tipo]));

        if (filteredData.length === 0) return [];

        const sortedData = filteredData.sort((a, b) => a - b);
        const q1 = sortedData[Math.floor((sortedData.length / 4))];
        const median = sortedData[Math.floor((sortedData.length / 2))];
        const q3 = sortedData[Math.floor((sortedData.length * (3 / 4)))];
        const min = sortedData[0];
        const max = sortedData[sortedData.length - 1];

        return [[min, q1, median, q3, max]];
      };

      const dataTPS = procesarDatos(this.mutaciones, 'valor_tps');
      const dataCPS = procesarDatos(this.mutaciones, 'valor_cps');

      return {
        credits: { enabled: true },
        chart: { type: 'boxplot', zoomType: 'x' },
        title: { text: "PDL1 Test Results" },
        xAxis: { categories: ['TPS', 'CPS'], crosshair: true, labels: { rotation: -45, style: { fontSize: '13px' }}},
        yAxis: { min: 0, title: { text: 'Value' }},
        tooltip: {
          useHTML: true,
          shared: true,
          formatter: function() {
            let tooltip = `<span style="font-size:10px">${this.x} - TPS AND CPS</span><table>`;
            this.points.forEach(point => {
              tooltip += `<tr><td style="color:${point.color};padding:0">${point.series.name} Min: </td>` +
                `<td style="padding:0"><b>${point.point.low}</b></td></tr>` +
                `<tr><td style="color:${point.color};padding:0">${point.series.name} Q1: </td>` +
                `<td style="padding:0"><b>${point.point.q1}</b></td></tr>` +
                `<tr><td style="color:${point.color};padding:0">${point.series.name} Median: </td>` +
                `<td style="padding:0"><b>${point.point.median}</b></td></tr>` +
                `<tr><td style="color:${point.color};padding:0">${point.series.name} Q3: </td>` +
                `<td style="padding:0"><b>${point.point.q3}</b></td></tr>` +
                `<tr><td style="color:${point.color};padding:0">${point.series.name} Max: </td>` +
                `<td style="padding:0"><b>${point.point.high}</b></td></tr>`;
            });
            tooltip += '</table>';
            return tooltip;
          }
        },
        plotOptions: {
          boxplot: {
            fillColor: '#F0F0E0',
            lineWidth: 2,
            medianWidth: 3,
            stemWidth: 1,
            whiskerWidth: 1
          }
        },
        series: [
          {
            name: 'TPS',
            data: dataTPS
          },
          {
            name: 'CPS',
            data: dataCPS
          }
        ]
      };
    }
  },
  methods: {
    async actualizarGrafica() {
      try {
        this.cargandoDatos = true;
        const resp = await obtenerDatosKPI(this, '/pdl1-tps-cps', this.$store.getters.objetoPeticion(),  this.selectedCountry, process.env.VUE_APP_URL_MS);
        this.graficaVacia = !resp.length;
        if (!this.graficaVacia) {
          this.categorias = ['TPS', 'CPS'];
          this.mutaciones = resp;
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
        this.graficaVacia = true;
      } finally {
        this.cargandoDatos = false;
      }
    }
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  min-height: 400px;
  height: 100%;
}
.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-weight: 500;
}
</style>
