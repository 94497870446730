import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './styles/styles.css'
import store from './store/store'
import VueHighcharts from 'highcharts-vue'
import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
import { highchartsConfigInit } from './config/highcharts.js'

More(Highcharts) // Agregar highcharts-more
Vue.config.productionTip = false
highchartsConfigInit();

Vue.use(VueHighcharts, { Highcharts });
new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
