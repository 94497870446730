<template>
  <div class="kpi">
    <DetallesOtros
      :dialog="mostrarModal"
      @close="mostrarModal = false"
      :otrosTiposTumoral="otrosTiposTumoral"
    />
    <v-skeleton-loader
      v-if="cargandoDatos"
      class="cargando"
      type="image"
      height="500px"
      max-height="500px"
    ></v-skeleton-loader>
    <div v-else>
      <div class="graficaVacia" v-if="graficaVacia">
        <v-chip>No existen registros</v-chip>
      </div>
      <div v-if="!graficaVacia">
        <highcharts :options="chartOptions"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import DetallesOtros from "../Modals/DetallesOtros.vue";
import obtenerDatosKPI from "../../services/graficas/obtenerDatos";
import { formatoMiles } from "../../utils/number-format";

export default {
  name: "DeterminacionesTipoTumoral",
  props: {
    selectedCountry: {
      type: String,
      default: 'argentina',
    },
  },

  components: { highcharts: Chart, DetallesOtros },
  data() {
    return {
      mostrarModal: false,
      cargandoDatos: true,
      graficaVacia: false,
      otrosTiposTumoral: [],
      colors: [
        "#EC4860",
        "#FDCA40",
        "#356CFF",
        "#5AC5F3",
        "#3ECF8E",
        "#9E88BE",
        "#7A6374",
        "#9BAE83",
      ],
      filtros: this.$store.state.filtros,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "pie",
          height: 500,
        },
        title: {
          align: "center",
          floating: true,
          text: "",
          style: { fontSize: "30px", fontWeight: 500, color: "#414D55" },
        },
        plotOptions: {
          pie: {
            shadow: false,
          },
          series: {
            point: {
              events: {
                click: function () {
                  if (this.name === "Otros") {
                    // 'this' aquí se refiere al punto, necesitamos acceder al componente
                    this.series.chart.options.custom.mostrarDetalles();
                  }
                },
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${this.point.name}</b> <br/> 
              Total: ${this.point.custom}`;
          },
        },
        series: [
          {
            name: "Tipo Tumoral",
            data: [],
            innerSize: "60%",
            dataLabels: {
              borderRadius: 5,
              backgroundColor: "white",
              shadow: true,
              borderWidth: 1,
              borderColor: "#AAA",
              formatter: function () {
                return `${this.point.name}: ${this.y}%`;
              },
            },
          },
        ],
        custom: {
          mostrarDetalles: this.mostrarDetalles.bind(this),
        },
      },
    };
  },
watch: {
  filtros: {
    handler() {
      this.actualizarGrafica();
    },
    deep: true,
  },
  selectedCountry() {
    this.actualizarGrafica();
  },
},

  methods: {
    mostrarDetalles() {
      this.mostrarModal = true;
    },
    transformarRespuesta(respuesta = [], max) {
      this.otrosTiposTumoral = [];
      const otros = { descripcion: "Otros", total: 0 };
      const resultado = [];
      respuesta.forEach((r) => {
        if (parseInt(((r.total / max) * 100).toFixed(0)) >= 3)
          resultado.push(r);
        else {
          otros.total += r.total;
          this.otrosTiposTumoral.push(r);
        }
      });
      if (otros.total > 0) resultado.push(otros);
      return resultado;
    },
    actualizarGrafica() {
      if (this.filtros.fecha.desde) {
        this.cargandoDatos = true;
        obtenerDatosKPI(
          this,
          "/determinaciones-tipo-tumoral",
          this.$store.getters.objetoPeticion(),
          this.selectedCountry,
          process.env.VUE_APP_URL_MS
        )
          .then((resp) => {
            if (resp.length > 0) {
              const max = resp.reduce((total, currentValue) => {
                return total + currentValue.total;
              }, 0);
              resp = this.transformarRespuesta(resp, max);
              this.graficaVacia = false;
              this.chartOptions.title.text = ` Total: ${formatoMiles(max)}`;
              this.chartOptions.series[0].data = resp.map((r, index) => {
                return {
                  name: r.descripcion,
                  color: this.colors[index],
                  y: parseInt(((r.total / max) * 100).toFixed(0)),
                  custom: r.total,
                };
              });
            } else {
              this.graficaVacia = true;
            }
            this.cargandoDatos = false;
          })
          .catch((error) => {
            console.error("Error al obtener los datos:", error);
            this.cargandoDatos = false;
            this.graficaVacia = true;
          });
      }
    },
  },
  created() {
    this.actualizarGrafica();
  },
};
</script>

<style scoped>
.kpi {
  min-height: 500px;
}
.graficaVacia {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  font-weight: 500;
}
</style>
