<template>
  <v-dialog
        v-model="mostrar"
        width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Detalles</span>
          </v-card-title>
          <v-simple-table>
          <template v-slot:default>
            <thead class="encabezado-tabla">
              <tr>
                <th class="text-left header">Tipo tumoral</th>
                <th class="text-center header">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tipoTumoral in otrosTiposTumoral" :key="tipoTumoral.descripcion">

                <td class="text-center">{{ tipoTumoral.descripcion }}</td>
                <td class="text-center">{{ tipoTumoral.total }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    name: 'DetallesOtros',
    props: {
      selectedCountry: {
    type: String,
    default: 'argentina',
  },
        dialog: Boolean,
        otrosTiposTumoral: Array
    },
    computed: {
        mostrar: {
            get() {
                return this.dialog;
            },
            set(){
                this.$emit('close');
            }
        }
    },
}
</script>

<style>
.encabezado-tabla {
  background: #f2f2f2;
}
</style>