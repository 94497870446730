<template>
  <v-card elevation="1" class="card">
    <div class="container-filtros">
      <div class="top">
        <h3 class="titulo-filtros">Filtros</h3>
        <v-btn
          text
          rounded
          v-bind:class="[filtrosVacios ? 'btn-reset' : 'btn-reset-activo']"
          @click="resetFiltros"
          :disabled="filtrosVacios"
        >
          Reset
        </v-btn>
      </div>
      <div class="section">biomarcadores</div>
      <div class="field">
        <Autocomplete
          :filtro="'biomarcador'"
          :etiqueta="'Buscar biomarcador'"
          :selectedCountry="selectedCountry"
          ref="filtroBiomarcador"
        />
      </div>
      <div class="section">sponsor</div>
      <div class="field">
        <Autocomplete
          :filtro="'sponsor'"
          :etiqueta="'Seleccione un sponsor'"
          :selectedCountry="selectedCountry"
          ref="filtroSponsor"
        />
      </div>
      <div class="section">País de origen de la muestra</div>
      <div class="field">
        <Autocomplete
          :filtro="'laboratorio'"
          :etiqueta="'Seleccione el país de origen'"
          :selectedCountry="selectedCountry"
          ref="filtroLaboratorio"
        />
      </div>
      <div class="section">Fecha</div>
      <div class="dates">
        <v-menu
          v-model="menuFechaDesde"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaDesde"
              label="DESDE"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="purple darken-2"
            no-title
            :min="fechaMasAntigua"
            :max="fechaHasta"
            v-model="fechaDesde"
            @input="menuFechaDesde = false"
            locale="es-ES"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="menuFechaHasta"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fechaHasta"
              label="HASTA"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="purple darken-2"
            no-title
            :min="fechaDesde"
            :max="hoy"
            v-model="fechaHasta"
            @input="menuFechaHasta = false"
            locale="es-ES"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="section">tipo tumoral</div>
      <div class="field">
        <Autocomplete
          :filtro="'tipoTumoral'"
          :etiqueta="'Buscar tipo'"
          :selectedCountry="selectedCountry"
          ref="filtroTipoTumoral"
        />
      </div>
      <div class="section">tipo resultado</div>
      <div class="input-select last-field">
        <Autocomplete
          :filtro="'tipoResultado'"
          :etiqueta="'Seleccione un resultado'"
          :selectedCountry="selectedCountry"
          ref="filtroTipoResultado"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import Autocomplete from "./Filters/Autocomplete";
//import Select from "./Filters/Select";
export default {
  name: "Filtros",
    props: {
    selectedCountry: {
      type: String,
      default: 'argentina',
    },
  },
  components: {
    Autocomplete,
    //Select,
  },
  data() {
    return {
      hoy: new Date().toISOString().slice(0, 10),
      menuFechaDesde: null,
      menuFechaHasta: null,
      filtrosVacios: true,
    };
  },
  computed: {
    fechaMasAntigua: {
      get() {
        return this.$store.state.fechaMasAntigua;
      },
    },
    filtros() {
      return this.$store.state.filtros;
    },
    fechaHasta: {
      get() {
        return this.$store.state.filtros.fecha.hasta;
      },
      set(value) {
        this.$store.commit("setFechaHasta", value);
      },
    },
    fechaDesde: {
      get() {
        return this.$store.state.filtros.fecha.desde;
      },
      set(value) {
        this.$store.commit("setFechaDesde", value);
      },
    },
  },
  watch: {
    filtros: {
      handler() {
        this.filtrosVacios =
          this.filtros.biomarcador.length === 0 &&
          this.filtros.laboratorio.length === 0 &&
          this.filtros.sponsor.length === 0 &&
          this.filtros.tipoTumoral.length === 0 &&
          this.filtros.tipoResultado.length === 0 &&
          this.fechaDesde === this.fechaMasAntigua &&
          this.fechaHasta === this.hoy;
      },
      deep: true,
    },
  },
  methods: {
    resetFiltros() {
      this.$refs.filtroBiomarcador.reset();
      this.$refs.filtroTipoTumoral.reset();
      this.$refs.filtroLaboratorio.reset();
      this.$refs.filtroSponsor.reset();
      this.$refs.filtroTipoResultado.reset();
      this.$store.commit("setFechaDesde", this.fechaMasAntigua);
      this.$store.commit("setFechaHasta", this.hoy);
    },
  },
  created(){
    if (!this.fechaMasAntigua){ 
      this.$store.dispatch('inicializarFechaDesde')
    }
  },
  destroyed(){
    this.$store.dispatch('limpiarFiltros')
  }
};
</script>

<style scoped>
.card {
  height: fit-content;
}

.field {
  margin: 0.4rem 1.5rem;
}

.input-select {
  margin: 0.8rem 1.5rem;
}

.last-field {
  margin-bottom: 1.8rem;
}

.btn-reset {
  text-transform: capitalize;
  background: #b4b4b4;
}

.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}

.btn-reset-activo {
  text-transform: capitalize;
  background: #842e7c;
  color: white;
}

.container-filtros{
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.dates {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem 0;
  row-gap: 1rem;
}

.top {
  margin: 1rem 1.5rem 0;
  display: flex;
  justify-content: space-between;
}

.titulo-filtros {
  font-weight: 600;
  font-size: 18px;
}

.section {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  background: #f2f2f2;
  padding: 0.5rem 1.5rem;
}
</style>