import Highcharts from 'highcharts'
import loadDrillDown from 'highcharts/modules/drilldown'

export function highchartsConfigInit() {
    loadDrillDown(Highcharts);
    Highcharts.setOptions({
        lang: {
            drillUpText: 'Regresar'
        }
    });

    Highcharts.seriesTypes.pie.prototype.setTitle = function(titleOption) {
        var chart = this.chart,
            center = this.center || (this.yAxis && this.yAxis.center),
            box,
            format;

        if (center && titleOption) {
            box = {
                x: chart.plotLeft + center[0] - 0.5 * center[2],
                y: chart.plotTop + center[1] - 0.5 * center[2],
                width: center[2],
                height: center[2]
            };

            format = titleOption.text || titleOption.format;
            format = Highcharts.format(format, this);

            if (this.title) {
                this.title.attr({
                    text: format
                });

            } else {
                this.title = this.chart.renderer.label(format)
                    .css(titleOption.style)
                    .add()
            }
            const labelBBox = this.title.getBBox();
            titleOption.width = labelBBox.width;
            titleOption.height = labelBBox.height;
            this.title.align(titleOption, null, box);
        }
    };

    Highcharts.seriesTypes.pie.prototype.setSubtitle = function(titleOption) {
        var chart = this.chart,
            center = this.center || (this.yAxis && this.yAxis.center),
            box,
            format;

        if (center && titleOption) {
            box = {
                x: chart.plotLeft + center[0] - 0.5 * center[2],
                y: chart.plotTop + center[1] - 0.5 * center[2],
                width: center[2],
                height: center[2]
            };

            format = titleOption.text || titleOption.format;
            format = Highcharts.format(format, this);

            if (this.subtitle) {
                this.subtitle.attr({
                    text: format
                });

            } else {
                this.subtitle = this.chart.renderer.label(format)
                    .css(titleOption.style)
                    .add()
            }
            const labelBBox = this.subtitle.getBBox();
            titleOption.width = labelBBox.width;
            titleOption.height = labelBBox.height;
            this.subtitle.align(titleOption, null, box);
        }
    };

    Highcharts.wrap(Highcharts.seriesTypes.pie.prototype, 'render', function(proceed) {
        proceed.call(this);
        this.setTitle(this.options.title);
        this.setSubtitle(this.options.subtitle)
    });
}